<template>
  <div
    class="category-item pa-2 v-clickable"
    @click="$emit('click', $event)">
    <v-img
      :src="thumbnailUrl"
      cover
      class="category-cover mb-4" />
    <div class="category-name px-2">
      {{ name }}
    </div>
  </div>
</template>

<script>
  import CategoryItemMixin from '@/components/categories/category-item/CategoryItem.mixin.vue';
  export default {
    mixins: [CategoryItemMixin],
  };
</script>

<style lang="css" scoped>
  .category-item {
    background-color: var(--v-offWhite-base);
    border-radius: 24px;
  }
  .v-clickable {
    cursor: pointer;
  }
  .category-cover {
    width: 144px;
    height: 144px;
    border-radius: 24px;
  }
  .category-name {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: var(--v-offBlack-base);
    width: 144px;
  }
</style>
